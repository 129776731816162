import { createStore } from 'vuex'
import TutelaService from '../services/tutela.service'

const _TutelaService = new TutelaService()

const storeTutela = {
  state: {
    tutela: {
      id: null,
      numRadicado: null,
      accionanteId: null,
      representanteId: null,
      juzgadoId: null,
      fechaExpedicion: null,
      fechaRecepcion: null,
      horaRecepcion: null,
      fechaVencimiento: null,
      horaVencimiento: null,
      provisonalidad: false,
      userResponsableId: null,
      observaciones: null,
      representante: {
        id: null,
        fullname: null,
        numeroIdentificacion: null,
        docTipoId: null,
        telefono: null,
        correo: null,
        ldepartamentoId: null,
        municipioId: null
      },
      accionante: {
        id: null,
        fullname: null,
        numeroIdentificacion: null,
        docTipoId: null,
        telefono: null,
        correo: null,
        departamentoId: null,
        municipioId: null,
        representanteLegal: null,
        direccion: null
      },
      juzgado: {
        id: null,
        nombre: null,
        categoriaId: null,
        departamentoId: null,
        municipioId: null
      },
      fallos: []
    },
    _tutelas: {
      rows: [],
      count: 0
    },
    _showTutela: {
      numRadicado: null,
      fechaVencimiento: null,
      horaVencimiento: null,
      fechaRecepcion: null,
      horaRecepcion: null,
      accionante: {
        fullname: null,
        correo: null,
        docTipoId: null,
        numeroIdentificacion: null,
        telefono: null,
        localizacion: null,
        representanteLegal: null,
        direccion: null
      },
      juzgado: {
        code: null,
        localizacion: null,
        categoria: {
          nombre: null
        }
      },
      fallos: [
        {
          id: null,
          fechaNotificacion: null,
          observacion: null,
          createdByNameFallos: {
            fullName: null
          },
          estadosFallo: {
            name: null
          },
          impugnacion: false,
          impugnaciones: {
            falloImpugnacion: {
              id: null,
              fechaNotificacion: null,
              observacion: null,
              fechaVencimiento: null,
              createdByNameFallos: {
                fullName: null
              },
              paths: []
            }
          },
          fechaVencimiento: null,
          paths: []
        }
      ]
    }
  },
  getters: {
    _tutela: state => state.tutela,
    tutelas: state => state._tutelas,
    showTutela: state => state._showTutela
  },
  mutations: {
    setAccionante (state, payload) {
      state.tutela.accionanteId = payload.id ?? null
      state.tutela.accionante = {
        id: payload.id ?? null,
        fullname: payload.fullname,
        numeroIdentificacion: payload.numeroIdentificacion,
        docTipoId: payload.docTipoId,
        telefono: payload.telefono,
        correo: payload.correo,
        departamentoId: payload.departamentoId,
        municipioId: payload.municipioId,
        representanteLegal: payload.representanteLegal,
        direccion: payload.direccion
      }
    },
    setDefault (state) {
      state.tutela.accionante = {
        fullname: '',
        numeroIdentificacion: '',
        docTipoId: '',
        telefono: '',
        correo: '',
        departamentoId: '',
        municipioId: '',
        representanteLegal: '',
        direccion: '',
        accionanteId: ''
      }
    },
    updateAccionante (state, payload) {
      state.tutela.accionante = {
        fullname: payload.fullname,
        numeroIdentificacion: payload.numeroIdentificacion,
        docTipoId: payload.docTipoId,
        telefono: payload.telefono,
        correo: payload.correo,
        departamentoId: payload.departamentoId,
        municipioId: payload.municipioId,
        representanteLegal: payload.representanteLegal,
        direccion: payload.direccion
      }
    },
    setJuzgado (state, payload) {
      state.tutela.juzgadoId = payload.id ?? null
      state.tutela.juzgado = {
        id: payload.id,
        nombre: payload.nombre,
        categoriaId: payload.categoriaId,
        categoria: payload.categoria,
        departamentoId: payload.departamentoId,
        municipioId: payload.municipioId,
        code: payload.code
      }
    },
    setDetalleTutela (state, payload) {
      state.tutela.numRadicado = payload.numRadicado
      state.tutela.fechaExpedicion = payload.fechaExpedicion
      state.tutela.fechaRecepcion = payload.fechaRecepcion
      state.tutela.horaRecepcion = payload.horaRecepcion
      state.tutela.fechaVencimiento = payload.fechaVencimiento
      state.tutela.horaVencimiento = payload.horaVencimiento
      state.tutela.provisonalidad = payload.provisonalidad
      state.tutela.provisonalidadFecha = payload.provisonalidadFecha
      state.tutela.provisonalidadHora = payload.provisonalidadHora
      state.tutela.userResponsableId = payload.userResponsableId
      state.tutela.observaciones = payload.observaciones
      state.tutela.soportesJuridicos = payload.soportesJuridicos
    },
    setResponsableId (state, payload) {
      state.tutela.userResponsableId = payload
    },
    setReset (state) {
      state.tutela = {
        id: null,
        numRadicado: null,
        accionanteId: null,
        representanteId: null,
        juzgadoId: null,
        fechaExpedicion: null,
        fechaRecepcion: null,
        horaRecepcion: null,
        fechaVencimiento: null,
        horaVencimiento: null,
        provisonalidad: false,
        userResponsableId: null,
        observaciones: null,
        accionante: {
          id: null,
          fullname: null,
          numeroIdentificacion: null,
          docTipoId: null,
          telefono: null,
          correo: null,
          departamentoId: null,
          municipioId: null,
          representanteLegal: null,
          direccion: null
        },
        representante: {
          id: null,
          fullname: null,
          numeroIdentificacion: null,
          docTipoId: null,
          telefono: null,
          correo: null,
          ldepartamentoId: null,
          municipioId: null
        },
        juzgado: {
          id: null,
          nombre: null,
          categoriaId: null,
          departamentoId: null,
          municipioId: null,
          code: null
        },
        fallos: {}
      }
    },
    setTutelas (state, payload) {
      state._tutelas = payload
    },
    setShowTutela (state, payload) {
      state._showTutela = payload
    },
    setTutela (state, payload) {
      state.tutela = payload
    }
  },
  actions: {
    onSave ({ getters }) {
      return new Promise((resolve) => {
        _TutelaService.onSave(getters._tutela).then(({ data }) => {
          if (data) {
            resolve(true)
          }
        })
      })
    },
    getPaginate ({ commit }, payload) {
      return _TutelaService.getPaginate(payload).then(({ data }) => {
        commit('setTutelas', data)
      })
    },
    onShow ({ commit }, payload) {
      _TutelaService.onShow(payload).then(({ data }) => {
        commit('setShowTutela', data)
      })
    },
    find ({ commit }, payload) {
      _TutelaService.onShow(payload).then(({ data }) => {
        commit('setTutela', data)
      })
    }
  }
}
const store = createStore(storeTutela)
export default {
  namespaced: true,
  ...store
}
