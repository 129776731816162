import { createStore } from 'vuex'
import LocalizacionService from '@/apps/pharmasan/juridica/procesosJuridicos/services/localizacion.service'
const _LocalizacionService = new LocalizacionService()
const storeLocalizacion = {
  state: {
    departamentos: [],
    municipios: [],
    municipiosJuzgado: []
  },
  getters: {
    _departamentos: state => state.departamentos,
    _municipios: state => state.municipios,
    _municipiosJuzgado: state => state.municipiosJuzgado
  },
  mutations: {
    setDepartamento (state, payload) {
      state.departamentos = payload
    },
    setMunicipios (state, payload) {
      state.municipios = payload
    },
    setMunicipiosJuzgado (state, payload) {
      state.municipiosJuzgado = payload
    }
  },
  actions: {
    getDeparatamentos ({ commit }) {
       return new Promise((resolve, reject) => {
         _LocalizacionService.getDpto().then(({ data }) => {
           commit('setDepartamento', data)
           resolve(true)
         })
       })
    },
    getMunicipios ({ commit }, payload) {
       return new Promise((resolve, reject) => {
         _LocalizacionService.getMpio(payload).then(({ data }) => {
           commit('setMunicipios', data)
           resolve(true)
         })
       })
    },
    getMunicipiosJuzgado ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        _LocalizacionService.getMpio(payload).then(({ data }) => {
          commit('setMunicipiosJuzgado', data)
          resolve(true)
        })
      })
    }
  }
}
const store = createStore(storeLocalizacion)
export default {
  namespaced: true,
  ...store
}
