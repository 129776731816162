import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class LocalizacionService {
  getDpto () {
    return http.get(`${baseUrl}/localizacion/departamentos`)
  }

  getMpio (codDpto) {
    return http.get(`${baseUrl}/localizacion/municipios/${codDpto}`)
  }

  getMpios () {
    return http.get(`${baseUrl}/localizacion/municipios`)
  }
}
