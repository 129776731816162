import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class TutelaService {
  onSave (data) {
    return http.post(`${baseUrl}/tutelas`, data, {
      headers: {
        loading: true
      }
    })
  }

  getPaginate (params) {
    return http.get(`${baseUrl}/tutelas/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  onShow (id) {
    return http.get(`${baseUrl}/tutelas/show/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
